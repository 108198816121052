<script setup lang="ts">
const { t } = useT();

const props = defineProps<{
	subTitle: string;
	title: string;
	entries: number;
	finishedAt: string;
}>();

const emit = defineEmits<{ (event: "click"): void }>();

const handleClick = () => {
	emit("click");
};

const { durationLeft } = useCountdown({ timestamp: props.finishedAt, formatToken: "DD [d] HH [h] mm [m]" });
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: '/nuxt-img/advent/promo-advent.webp',
			alt: 'advent-logo',
			format: 'avif',
			loading: 'lazy'
		}"
		height="257px"
		@click="handleClick"
	>
		<template #top>
			<div class="block">
				<AText :size="10" :modifiers="['uppercase']" class="time">
					{{ t("advent.promo.time.ends") }} {{ durationLeft }}
				</AText>
				<AText as="div" :size="20" :modifiers="['bold']" class="title">
					{{ title }}
				</AText>
				<AText as="div" :size="12" :modifiers="['bold', 'uppercase']">
					<span v-html="subTitle" />
				</AText>
				<div v-if="entries !== 0" class="card-count entries">
					<MPrizeFund variant="entries" icon="20/entries" :icon-size="38">
						<AText :size="32" class="text-cixi" :modifiers="['black']">
							{{ numberFormat(entries) }}
						</AText>
					</MPrizeFund>
				</div>
			</div>
		</template>
		<template #bottom>
			<AButton variant="primary" size="md" data-tid="promo-open-mission">
				<AText :size="16" :modifiers="['bold', 'uppercase']">{{ t("advent.promo.btn.start") }}</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-count {
	display: flex;
	align-items: center;
	gap: 4px;
}

button {
	width: 240px;
}

.card {
	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	.time {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.title {
		min-height: 15px;
		margin-bottom: 16px;
	}
}

.icon-ticket {
	font-size: 24px;
	margin-left: 8px;
}
</style>
